<template>

</template>

<script>
export default {
  name: 'Transit',
  props: {
    path: {
      default: () => '',
    },
    query: {
      default: () => ({}),
    },
  },
  created() {
    let { path, query } = this;
    this.$router.replace({
      path,
      query: { ...query },
    })
  },
};
</script>
